import { Injectable, inject, signal } from "@angular/core";
import { type ActivatedRouteSnapshot, Router, type RouterStateSnapshot } from "@angular/router";
import { catchError, filter, map, of } from "rxjs";
import { ApiService } from "./api.service";
import { StateService } from "./state.service";

@Injectable({
	providedIn: "root",
})
export class UserService {
	private apiService = inject(ApiService);
	private stateService = inject(StateService);
	private router = inject(Router);

	userRoles = signal<UserRole | null>(null);

	checkRoutePermission(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.apiService.getUserRoles().result$.pipe(
			filter((response) => response.isSuccess || response.isError),
			map((response) => {
				if (response.isError) {
					this.router.navigate(["/access-denied"]);
					return false;
				}
				const routes = this.createLeftNavList(response.data);
				if (routes.length === 0) {
					this.router.navigate(["/access-denied"]);
					return false;
				}
				this.stateService.routeList.set(routes.sort((a, b) => a.order - b.order));
				this.userRoles.set(response.data);
				const path = route.routeConfig?.path;
				const responseData = response.data;
				if (
					path &&
					path === "dashboard" &&
					!Object.hasOwn(
						responseData.entityPermissions,
						pathAndPermission[path as keyof typeof pathAndPermission],
					)
				) {
					this.router.navigate([routes[0].path]);
					return true;
				}
				if (
					path &&
					Object.hasOwn(pathAndPermission, path) &&
					Object.hasOwn(
						responseData.entityPermissions,
						pathAndPermission[path as keyof typeof pathAndPermission],
					)
				) {
					return true;
				}
				this.router.navigate(["/access-denied"]);
				return false;
			}),
			catchError((error) => {
				console.error("Error fetching user roles:", error);
				this.router.navigate(["/access-denied"]);
				return of(false);
			}),
		);
	}

	createLeftNavList(roles: UserRole) {
		const routeList: typeof routerList = [];
		Object.keys(roles.entityPermissions).forEach((key) => {
			const keyPath = pathAndPermissionReverse[key as keyof typeof pathAndPermissionReverse];
			const element = routerList.find((item) => item.path === `/${keyPath}`);
			if (element) {
				routeList.push(element);
			}
		});
		return routeList;
	}

	checkPermission(entity: string, value: number) {
		const userRoles = this.userRoles();
		if (typeof userRoles?.entityPermissions !== "object") return false;

		if (
			Object.hasOwn(userRoles?.entityPermissions, entity) &&
			userRoles?.entityPermissions[entity] >= value
		) {
			return true;
		}
		return false;
	}
}

export const PermissionGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	return inject(UserService).checkRoutePermission(route, state);
};

const pathAndPermission = {
	dashboard: "Dashboard",
	"risk-element": "Risk Score Page",
	"risk-element/:id": "Risk Score Page",
	"element-details/:assetId/:elementId": "Risk Score Page",
	"review-plan/:id": "Risk Score Page",
	owner: "Risk Score Page",
	"risk-import": "Import Risk Data",
	"risk-coefficient": "Risk Coefficient",
	"risk-table": "Risk Tables",
	entitlement: "Organization Roles Management",
};

const pathAndPermissionReverse = {
	Dashboard: "dashboard",
	"Risk Score Page": "risk-element",
	"Import Risk Data": "risk-import",
	"Risk Coefficient": "risk-coefficient",
	"Risk Tables": "risk-table",
	"Organization Roles Management": "entitlement",
};

const routerList = [
	{ name: "Dashboard", path: "/dashboard", icon: "dashboard", type: "topLink", order: 1 },
	{
		name: "Risk Score",
		path: "/risk-element",
		icon: "score_speedometer",
		type: "topLink",
		order: 2,
	},
	{
		name: "Risk Import Data",
		path: "/risk-import",
		icon: "import_files_data",
		type: "topLink",
		order: 3,
	},
	{
		name: "Risk Coefficient",
		path: "/risk-coefficient",
		icon: "risk configuration coefficient",
		type: "topLink",
		order: 4,
	},
	{
		name: "Risk Tables",
		path: "/risk-table",
		icon: "database-table",
		type: "topLink",
		order: 5,
	},
	{
		name: "Entitlement",
		path: "/entitlement",
		icon: "user_entitlement",
		type: "bottomLink",
		order: 6,
	},
	{
		name: "User Logs",
		path: "/logs/system",
		icon: "user_logs",
		type: "bottomLink",
		order: 7,
	},
	{ name: "Help", path: "/help", icon: "help", type: "bottomLink", order: 8 },
];
export type RouterList = typeof routerList;

export type UserRole = {
	userEmail: string;
	firstName: string;
	lastName: string;
	roles: number[];
	entityPermissions: { [key: string]: number };
};
